import { Providers } from "@microsoft/mgt";
import {
  TeamsMsal2Provider,
  HttpMethod,
} from "@microsoft/mgt-teams-msal2-provider";
import * as MicrosoftTeams from "@microsoft/teams-js";
import { LOGO_PNG } from "../constants";

TeamsMsal2Provider.microsoftTeamsLib = MicrosoftTeams;

let graphClient = null;

function intiGraphClient() {
  const config = {
    clientId: process.env.VUE_APP_APPLICATION_ID,
    authPopupUrl: window.location.href + "auth",
    scopes: [
      "Directory.Read.All",
      "Chat.Read",
      "ChatMessage.Send",
      "Chat.ReadWrite",
      "ChannelMessage.Send",
      "ChannelMember.Read.All"
    ],
    ssoUrl: `${process.env.VUE_APP_API_URL}/msal-token`,
    httpMethod: HttpMethod.POST,
  };

  Providers.globalProvider = new TeamsMsal2Provider(config);
  graphClient = Providers.globalProvider.graph;
}

async function getGroupUsers(groupId) {
  return graphClient.api(`/groups/${groupId}/members`).get();
}

async function getChatUsers(email, chatId) {
  return graphClient.api(`/users/${email}/chats/${chatId}/members`).get();
}

function mapUsers(users, chatId) {
  let mappedUsers = users.map((u) => {
    return {
      displayName: u.displayName,
      emailAddress: chatId ? u.email : u.mail,
      recipientType: u["@odata.type"],
    };
  });
  return mappedUsers;
}

export const postNominationMessage = async (
  message,
  groupId,
  channelId,
  chatId
) => {
  try {
    let url = "";
    let content = {
      subtitle: message,
      images: [
        {
          url: window.location.origin + LOGO_PNG,
        },
      ],
    };

    const postMessage = {
      subject: "Power2Motivate Nomination",
      body: {
        contentType: "html",
        content:
          '<attachment id="74d20c7f34aa4a7fb74e2b30004247c5"></attachment>',
      },
      attachments: [
        {
          id: "74d20c7f34aa4a7fb74e2b30004247c5",
          contentType: "application/vnd.microsoft.card.thumbnail",
          content: JSON.stringify(content),
        },
      ],
    };

    intiGraphClient();

    if (chatId) {
      url = `/chats/${chatId}/messages`;
    } else {
      url = `/teams/${groupId}/channels/${channelId}/messages`;
    }

    await graphClient.api(url).post(postMessage);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchUsers = async (email, chatId, groupId) => {
  try {
    let members = null;
    let usersList = [];
    intiGraphClient();

    if (chatId) {
      members = await getChatUsers(email, chatId);
    } else if (groupId) {
      members = await getGroupUsers(groupId);
    }

    if (members.value && members.value.length) {
      usersList = mapUsers(members.value, chatId);
    }

    //Filter current user from the list of users
    return usersList.filter((e) => e.emailAddress != email);
  } catch (error) {
    console.log(error);
    return null;
  }
};

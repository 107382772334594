/**
 * Program URL returned after successful login.
 * Used to attach the /dash/<id> path when loading the page in an iFrame.
 */
export const URL = "url";
export const USED = "used";
export const ACTIVE_TAB = "active_tab";
export const APPOINTMENT = "appointment";
export const MESSAGE = "message";
export const FAILED = "failed";
export const TEAM_USERS = "team_users";
/**
 * We need to store last viewed page to be able to restore it when opening the add-in.
 * It is necessary to validate the code in email-based login workflow.
 * Without the last viewed page closing and opening the extension brings
 * user to the login workflow selection page not giving them a chance to enter the code.
 */
export const LAST_VIEWED_PAGE = "lastViewedPage";
/**
 * Key used to store the program domain that is passed as a proxy target.
 */
export const PROGRAM_ID = "programId";
export const DOMAIN_NAME = "domain";
// Regex used for validating the program domain entered by the user in program domain workflow.
export const VALIDATION_DOMAIN =
  "^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$";
// Regex used for validating e-mail address provided by the user in e-mail based login workflow.
export const VALIDATION_EMAIL = "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$";

/****** Look and Feel  *********/
export const LOGO_FALLBACK = "/logo-power-2-motivate.svg";
export const LOGO_PNG = "/logo-power-2-motivate.png";
export const LOGO_PATH = process.env.VUE_APP_LOGO_PATH;
export const VALIDATION_CODE_TIMEOUT_IN_SECONDS =
  process.env.VUE_APP_VALIDATION_CODE_TIMEOUT_IN_SECONDS || 1800; // 30 miuntes

// URL Path of the NW configured in env variable
export const NOMINATION_WIZARD_PATH =
  process.env.VUE_APP_NOMINATION_WIZARD_PATH;
// URL Path of the GRS (Storefront) configured in the env variable
export const STOREFRONT_PATH = process.env.VUE_APP_STOREFRONT_PATH;
export const HIDE_HEADER_OPTIONS_GET_TAB =
  process.env.HIDE_HEADER_OPTIONS_GET_TAB;
export const HIDE_HEADER_OPTIONS =
  process.env.VUE_APP_HIDE_HEADER_OPTIONS ||
  "dreamtracker, myaccount, gallery, myorders, logo, topbar, cart";
// LAYOUT configuration for GIVE (NW) tab
export const LAYOUT = process.env.VUE_APP_LAYOUT || 0;
// LAYOUT configuration for GET (Storefront) tab
export const LAYOUT_GET_TAB = process.env.VUA_APP_LAYOUT_GET || 0;
export const STOREFRONT_TAB_NAME = process.env.VUE_APP_STOREFRONT_TAB_NAME;
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const CSRF = '_csrf';
export const LOG_OUT_TAB_NAME = 'log-out';
export const PROGRAM_DETAIL = 'program_detail'
export const PROGRAMS = 'programs'

/****** Custom theme  *********/
export const APP_PRIMARY_COLOR = process.env.VUE_APP_PRIMARY_COLOR || ''
export const APP_FONT_COLOR = process.env.VUE_APP_FONT_COLOR || ''
export const APP_BUTTON_TEXT_COLOR = process.env.VUE_APP_BUTTON_TEXT_COLOR || ''
export const APP_SITE_BACKGROUND_COLOR = process.env.VUE_APP_SITE_BACKGROUND_COLOR || ''

<template>
  <Layout class="layout-login" :programLogo="programLogo()">
    <div class="tab-log-out" v-if="showIframe">
      <div class="login-card">
        <p class="card-title">{{ subprogramDetails?.name }}</p>
        <a
          id="email-id-link"
          class="card-title"
          >{{ subprogramDetails?.url }}</a
        >
      </div>
      <button class="block-button" @click="signOut">Log Out</button>
      <iframe
          v-if="showIframe"
          name="nomination-wizard"
          id="nomination"
          width="100%"
          height="100%"
          frameBorder="0"
          allow="camera; microphone"
          :src="iframSrc"
          @load="postMessageToIframe"
      >
      </iframe>
    </div>
    <div class="program-name-label" v-else>You are logged out</div>
  </Layout>
</template>

<script>
import { 
  URL, 
  DOMAIN_NAME,
  PROGRAM_ID,
  LAST_VIEWED_PAGE,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CSRF,
  PROGRAM_DETAIL,
  PROGRAMS
} from "../../constants";
import { getData, removeData, deleteCookie } from "./../../helpers/storage";
import { fetchUsers } from "../../helpers/graph.js";
import * as microsoftTeams from "@microsoft/teams-js";

export const NOMINATION_WIZARD_PATH =
  process.env.VUE_APP_NOMINATION_WIZARD_PATH;

let recipients = [];
let usersFetched = false;

let programDetail = getData(PROGRAM_DETAIL);

export default {
  created() {
    const url = getData(URL);

    if (url) {
      this.iframSrc = this.src + url;
      this.showIframe = true;
    }
  },
  data() {
    return {
      showIframe: false,
      iframSrc: null,
      src: getData(DOMAIN_NAME),
      subprogramDetails: programDetail ? JSON.parse(programDetail) : null,
    };
  },
  methods: {
    programLogo() {
      if (!this.subprogramDetails) return false;

      let programLogo = this.getProgramLogo(this.subprogramDetails);

      return programLogo;
    },
    getProgramLogo(subprogramDetails) {
      return `${subprogramDetails?.url}/asset/${subprogramDetails?.programLogo}`;
    },
    signOut() {
      this.postMessageDispatcher("signout");
      setTimeout(() => {
		    this.clearSession();
      }, 500);
    },
    postMessageDispatcher(event, params = {}) {
      const payload = {
        event,
        params,
      };

      const iframeWin = document.getElementById("nomination").contentWindow;
      iframeWin.postMessage(payload, `${this.src}/${NOMINATION_WIZARD_PATH}`);
    },
    postMessageToIframe() {
      microsoftTeams.getContext((context) => {
        const { userPrincipalName, chatId, groupId } = context;

        if (chatId || groupId) {
          if (!usersFetched) {
            fetchUsers(userPrincipalName, chatId, groupId).then((result) => {
              usersFetched = true;
              recipients = result;
              this.doPostMessage();
            });
          } else {
            this.doPostMessage();
          }
        } else {
          this.doPostMessage();
        }
      });
    },
    doPostMessage() {
      const payload = {
        event: "addNomineesByEmails",
        params: recipients,
      };
      this.loading = false;
      const iframeWin = document.getElementById("nomination").contentWindow;
      iframeWin.postMessage(payload, `${this.src}/${NOMINATION_WIZARD_PATH}`);
    },
    clearSession() {
      removeData(DOMAIN_NAME);
      removeData(PROGRAM_ID);
      removeData(LAST_VIEWED_PAGE);
      removeData(URL);
      removeData(PROGRAM_DETAIL);
      removeData(PROGRAMS),
      deleteCookie(ACCESS_TOKEN);
      deleteCookie(REFRESH_TOKEN);
      deleteCookie(CSRF);
      this.showIframe = false;
    },
  },
};
</script>

<style scoped>
@import "./log-out.scss";
</style>

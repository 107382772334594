<template>
  <Layout class="layout-program-list">
    <p class="font-medium title text-center">
      Programs for <br />
      <span class="email-text">{{ this.email }}</span>
    </p>

    <Loading v-if="loading" class="primary center program-list-loading" />

    <div class="program-list" v-else-if="!loading && programList?.length > 0">
      <div
        v-for="program in programList"
        :key="program.id"
        class="program-list-item"
      >
        <img :src="program.logoUrl" alt="logo" width="50" />
        <p class="program-list-name">{{ program.url }}</p>
        <button class="launch-button" @click="launch(program)">Launch</button>
      </div>
    </div>
    <div v-else class="program-list-no-records">No records found</div>
    <div class="no-program">
      <p>Not seeing your program?</p>
      <router-link to="/" class="different-link">
        Try a different email
      </router-link>
    </div>
  </Layout>
</template>

<script>
import axios from "axios";
import { PROGRAM_ID, DOMAIN_NAME, PROGRAMS } from "../../constants";
import { verifyDomainName } from "../../api/coe";
import { saveData, getData } from "./../../helpers/storage";

export default {
  data() {
    return {
      email: this.$route.query.email,
      programList: [],
      loading: false,
    };
  },
  created() {
    (async () => {
      try {
        this.loading = true;
        let programs = getData(PROGRAMS);
        this.getLogoUrls(programs ? JSON.parse(programs) : []);
      } catch (error) {
        console.error("Unable to submit code ", error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    })();
  },
  methods: {
    async launch(program) {
      saveData(DOMAIN_NAME, program.url);
      const { data } = await verifyDomainName({
        domainName: program.url,
      });
      if (data?.id) {
        saveData(PROGRAM_ID, data.id);
        this.$router.push({ path: "/login" });
      }
    },
    getLogoUrls(programs) {
      for (let program of programs) {
        const host = String(program.url)
          .replace("http://", "")
          .replace("https://", "");
        axios
          .get(`${program.url}/api/v1/Subprograms/findByHost?host=${host}`)
          .then((response) => {
            if (response.data) {
              response.data.logoUrl =
                program.url + "/asset/" + response.data.programLogo;
              this.programList.push({
                ...program,
                ...response.data,
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data)
                this.toaster(error.response.data.message);
            }
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "./../../styles/index.scss";
</style>

<style scoped>
@import "./program-list.scss";
</style>

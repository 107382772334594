<template>
  <Layout class="layout-verify-code">
    <div class="description">
      <p class="content">
        Please enter your verification code we have sent to your email at <br />
        <span class="countdown">{{ email }}</span
        >
      </p>
      <p class="content mt-10">
        The code expires in
        <span class="countdown">{{ tokenExpiresIn }} mins</span>
      </p>
    </div>

    <form @submit.prevent="submit">
      <div class="code-wrapper">
        <input
          v-model="code"
          type="number"
          name="code"
          id="code"
          ref="code"
          class="code"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          maxlength="6"
        />
      </div>

      <ErrorMessage :message="errorMessage" class="error-message" />

      <Loading v-if="loading" class="primary center" />
      <button
        ref="submitButton"
        v-else
        type="submit"
        class="block-button verify-code-button"
      >
        Next
      </button>
    </form>
    <div class="no-program">
      <p>Incorrect email?</p>
      <router-link to="/" class="different-link">
        Try a different email
      </router-link>
    </div>
  </Layout>
</template>

<script>
import { verifyCode } from "../../api/coe";
import { countDown } from "./../../helpers/countdown";
import { VALIDATION_CODE_TIMEOUT_IN_SECONDS, PROGRAMS } from "./../../constants";
import { saveData } from "./../../helpers/storage";

export default {
  data() {
    return {
      loading: false,
      tokenExpiresIn: "00:00",
      email: this.$route.query.email,
      code: "",
      errorMessage: "",
    };
  },
  created() {
    countDown(VALIDATION_CODE_TIMEOUT_IN_SECONDS, ({ minutes, seconds }) => {
      this.tokenExpiresIn = `${minutes}:${seconds}`;
    });
  },
  mounted() {
    this.$refs.code.focus();
  },
  methods: {
    keypress(event) {
      // Numbers only
      if (event.keyCode >= 48 && event.keyCode <= 57)
        this.nextField(event.target.getAttribute("data-next"));
      else event.preventDefault();
    },
    async keydown(event) {
      if (event.key === "Enter") {
        this.$refs.submitButton.click();
      } else if (event.key === "v" && event.ctrlKey) {
        event.preventDefault();

        this.code = await navigator.clipboard.readText();
      }
    },
    async submit() {
      try {
        this.errorMessage = "";
        this.loading = true;

        const programs = await verifyCode({
          code: this.code,
          email: this.email,
        });

        if (programs) {
          saveData(PROGRAMS, JSON.stringify(programs));
          this.$router.push({
            path: "/program-list",
            query: { email: this.email },
          });
        } else {
          this.errorMessage = "Invalid code";
        }
      } catch (error) {
        this.errorMessage = error;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./../../styles/index.scss";
</style>

<style lang="scss" scoped>
@import "./verify-code.scss";
</style>

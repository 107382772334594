import * as microsoftTeams from "@microsoft/teams-js";

/**
 * Attempts to read the token from Microsoft Teams. It is then used for token based single sign-on
 * @returns Promise<string|Error> - Returns authentication token from Microsoft Teams or Error
 */
export const getSSOToken = () => {
  return new Promise((resolve, reject) =>
    microsoftTeams.authentication.getAuthToken({
      successCallback: (token) => {
        resolve(token);
        microsoftTeams.appInitialization.notifySuccess();
      },
      failureCallback: (message) => {
        microsoftTeams.appInitialization.notifyFailure({
          reason: microsoftTeams.appInitialization.FailedReason.AuthFailed,
          message,
        });
        reject(message);
      },
      resources: [process.env.TAB_APP_URI],
    })
  );
};

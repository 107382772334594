function areCookiesAvailable() {
  return typeof document !== undefined && typeof document.cookie !== undefined;
}

export const saveData = function (name, value) {
  if (localStorage) {
    localStorage.setItem(name, value);
    return true;
  }
  else if (areCookiesAvailable()) {

    setCookie(name, value, 3600 * 1000 * 24 * 7);
    return true;
  }
  else {
    return false;
  }
};

export const getData = function (name) {
  if (localStorage) {
    return localStorage.getItem(name) || '';
  }
  else if (areCookiesAvailable()) {
    return getCookie(name);
  }
  return '';
};
  
export const removeData = function (name) {
  if (localStorage) {
    localStorage.removeItem(name);
  } else if (areCookiesAvailable()) {
    document.cookie = `${name}=;max-age=0;SameSite=None;Secure;`;
  }
};

export function setCookie(name, value, expiry) {
  let now = new Date();
  let time = now.getTime();
  time += expiry || (3600 * 24 * 1000);
  now.setTime(time);
  document.cookie = `${name}=${value};expires=${now.toUTCString()};SameSite=None;Secure;path=/`;
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

export function deleteCookie( name ) {
  document.cookie = `${name}=;max-age=0;SameSite=None;Secure;`;
}

  
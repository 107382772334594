<template>
  <div>
    <div v-if="loading" class="container h-100">
      <div class="d-flex justify-content-center align-items-center vh-100">
        <div
          class="spinner-border text-dark"
          style="width: 4rem; height: 4rem"
        ></div>
      </div>
    </div>
    <div class="iframe-window">
      <div class="sign-out" v-if="activeTab === ''"><span @click="signOut">Log Out</span></div>
      <iframe
        v-if="showIframe"
        name="nomination-wizard"
        id="nomination"
        width="100%"
        height="100%"
        frameBorder="0"
        allow="camera; microphone"
        :src="iframSrc"
        @load="postMessageToIframe"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import {
  URL,
  USED,
  ACTIVE_TAB,
  DOMAIN_NAME,
  PROGRAM_ID,
  LAST_VIEWED_PAGE,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CSRF,
  PROGRAM_DETAIL,
  PROGRAMS
} from "../../constants";
import * as microsoftTeams from "@microsoft/teams-js";
import { fetchUsers, postNominationMessage } from "../../helpers/graph.js";
import { saveData, getData, removeData, deleteCookie } from "./../../helpers/storage";

export const NOMINATION_WIZARD_PATH =
  process.env.VUE_APP_NOMINATION_WIZARD_PATH;

let recipients = [];
let usersFetched = false;

export default {
  name: "App",
  beforeUnmount() {
    clearInterval(this.keepAliveInterval);
    clearInterval(this.keepSpaAliveInterval);
  },

  beforeMount: async function () {
    this.keepSpaAliveInterval = setInterval(this.continueSpaSession, 60000);
    //add listenerEvents
    window.addEventListener("message", (message) => {
      if (message.data.event === "widgetInitialized") {
        this.keepAliveInterval = this.keepAlive();
        this.postMessageToIframe();
      }
      // ...continueSession
    });
  },
  created() {
    const url = getData(URL);

    if (url) {
      this.iframSrc = this.src + url;
      this.showIframe = true;
      saveData(USED, true);
    } else {
      this.$router.push({ path: "/" });
    }
  },
  data() {
    return {
      loading: true,
      showIframe: false,
      iframSrc: null,
      recipients: null,
      src: getData(DOMAIN_NAME),
      activeTab: getData(ACTIVE_TAB)
    };
  },
  methods: {
    signOut() {
      this.postMessageDispatcher("signout");
      setTimeout(() => {
		    this.clearSession();
      }, 500);
    },
    keepAlive() {
      const payload = {
        event: "heartbeat",
        params: {
          notUsePreLoader: true,
        },
      };

      const iframeWin = document.getElementById("nomination").contentWindow;

      return setInterval(() => {
        iframeWin.postMessage(payload, "*");
      }, 120000);
    },
    continueSpaSession() {
      const payload = {
        event: "continueSession"
      };

      const nominationObject = document.getElementById("nomination");
      if (nominationObject && nominationObject.contentWindow) {
        const iframeWin = nominationObject.contentWindow;
        iframeWin.postMessage(payload, "*");
      }
    },
    postMessageToIframe() {
      microsoftTeams.getContext((context) => {
        const { userPrincipalName, chatId, groupId } = context;

        if (chatId || groupId) {
          if (!usersFetched) {
            fetchUsers(userPrincipalName, chatId, groupId).then((result) => {
              usersFetched = true;
              recipients = result;
              this.doPostMessage();
            });
          } else {
            this.doPostMessage();
          }
        } else {
          this.doPostMessage();
        }
      });
    },
    doPostMessage() {
      const payload = {
        event: "addNomineesByEmails",
        params: recipients,
      };
      this.loading = false;
      const iframeWin = document.getElementById("nomination").contentWindow;
      iframeWin.postMessage(payload, `${this.src}/${NOMINATION_WIZARD_PATH}`);
    },
    postChatMessage(message) {
      microsoftTeams.getContext((context) => {
        const { chatId, groupId, channelId } = context;
        postNominationMessage(message, groupId, channelId, chatId);
      });
    },
    postMessageDispatcher(event, params = {}, options = {}) {
      const payload = {
        event,
        params,
      };
      if (options.loading) {
        this.loading = true;
      } else {
        this.loading = false;
      }

      const iframeWin = document.getElementById("nomination").contentWindow;
      iframeWin.postMessage(payload, `${this.src}/${NOMINATION_WIZARD_PATH}`);
    },
    // After logout we need to clear the data from localStorage
    // and redirect user to the program selection page.
    clearSession() {
      removeData(DOMAIN_NAME);
      removeData(PROGRAM_ID);
      removeData(LAST_VIEWED_PAGE);
      removeData(URL);
      removeData(PROGRAM_DETAIL);
      removeData(PROGRAMS),
      deleteCookie(ACCESS_TOKEN);
      deleteCookie(REFRESH_TOKEN);
      deleteCookie(CSRF);
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped>
@import "./home.scss";
</style>

import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/home/Home.vue";
import Login from "../views/login/Login.vue";
import Initial from "../views/initial/Initial";
import Auth from "../views/auth/Auth";
import VerifyCode from "../views/verify-code/VerifyCode";
import ProgramList from "../views/program-list/ProgramList";
import LogOut from "../views/log-out/LogOut";

const routes = [
  {
    path: "/",
    name: "Initial",
    component: Initial,
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/verify-code",
    name: "VerifyCode",
    component: VerifyCode,
  },
  {
    path: "/program-list",
    name: "ProgramList",
    component: ProgramList,
  },
  {
    path: "/home",
    name: "NominationWizard",
    component: Home,
  },
  {
    path: "/log-out",
    name: "LogOut",
    component: LogOut,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

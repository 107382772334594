/**
 * @description
 * API's specific to the specific program.
 * Those API's require a valid DOMAIN_NAME to be provided.
 */
import * as microsoftTeams from "@microsoft/teams-js";
import { HTTP } from "../base";
import { domainStringToURL, domainStringToFQDN } from "./../utils";
import { LayoutManager } from "../tabConfigurationManager";
import { DOMAIN_NAME, ACTIVE_TAB } from "../../constants";
import { saveData, getData } from "./../../helpers/storage";

/**
 * @typedef {object} ProgramDetail
 * @property {number} id
 * @property {number} partnerId
 * @property {string} name
 * @property {string} companyName
 * @property {null} companyUrl
 * @property {number} skinId
 * @property {string} provinceState
 * @property {string} country
 * @property {number} registerEnableRegister
 * @property {number} registerEnableForgot
 * @property {number} resellerId
 * @property {number} pointBillingRate
 * @property {number} accountFreezeFlag
 * @property {number} rewardStyle
 * @property {string} plateauContext
 * @property {null} showProgramdetailsFlag
 * @property {string} note
 * @property {number} pointsFormulaId
 * @property {boolean|null} hideProgramLogoFlag
 * @property {string|null} companyLogo
 * @property {string} programLogo
 * @property {number} isDisabled
 * @property {number} flags
 * @property {null} gaAccount
 * @property {number} requireTandc
 * @property {string} defaultLanguage
 * @property {number} grsGalleryId
 * @property {string} grsGalleryCurrencyCode
 * @property {string} code
 * @property {string} programType
 * @property {string} defaultOperatingCurrencyCode
 */

var activeTab = "";
/**
 * Begins the session for the current user interaction with the program.
 * At this point we don't know yet if user is authenticated or not.
 * This request will just set the cookie for the user so the consecutive
 * request will be on the same session.
 *
 * NOTE: Session initialization is scoped to the program URL and subprogramId.
 * Before initializing the session we must set DOMAIN_NAME local storage value.
 * The DOMAIN_NAME value will be passed to the proxy server for further interactions.
 * @param {string} subprogramId Unique identifier for the program.
 * @returns Promise<>
 */
export const initialize = (subprogramId) => {
  // Get context from teams to set redirect path
  microsoftTeams.getContext((context) => {
    activeTab = context.entityId || "";
    saveData(ACTIVE_TAB, activeTab);
  });

  return HTTP.get(`/api/v1/Subprograms/init?subprogramId=${subprogramId}`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      url: domainStringToFQDN(getData(DOMAIN_NAME)),
    },
  });
};

/**
 * @description
 * Based on the tab `Give` or `Get` that is used to launch the NW (Nomination Wizard),
 * we will pass different options for layout.
 * @param {string} activeTab - Id of the tab from which user starts the NW.
 * @returns
 */
const _getSsoOptionsByTab = async () => {
  return new Promise((resolve, reject) => {
    microsoftTeams.getContext((context) => {
      if (!context) {
        return reject(
          `[microsoftTeams.getContext]: Unable to retrieve the context`,
          context
        );
      }
      activeTab = context.entityId;
      const ssoOptionsManager = new LayoutManager(activeTab);
      console.log("sso options Manager layout : ", ssoOptionsManager.layout);

      resolve(ssoOptionsManager.layout);
    });
  });
};
/**
 * Attempts to authenticate already initialized session. @see `initialize`
 * @param {Object} loginPayload
 * @param {string} loginPayload.memberId - Unique identifier of the memeber
 * @param {string} loginPayload.password - User password
 * @param {Object} loginConfiguration
 *
 * @returns Promise<>
 */
export const login = async (
  { token },
  { layout, redirect, hideHeaderOptions } = {}
) => {
  let ssoOptions = await _getSsoOptionsByTab();
  if (layout) {
    ssoOptions.layout = layout;
  }
  if (redirect) {
    ssoOptions.redirect = redirect;
  }
  if (hideHeaderOptions) {
    ssoOptions.hideHeaderOptions = hideHeaderOptions;
  }

  return HTTP.get("/api/v1/Sso/getSsoSession", {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      url: domainStringToFQDN(getData(DOMAIN_NAME)),
      "Authorization": `Bearer ${token}`
    },
    params: {
      ssoOptions,
    },
  });
};

/**
 * @description Retrieve the program information by domain name
 * @param {Object} payload
 * @param {string} payload.domainName - Name of the program domain to validate. Must be full name with protocol: i.e. https://example.com
 * @returns Promise<ProgramDetail|false>
 */
 export const verifyDomainName = ({ domainName }) => {
  const programURL = domainStringToURL(domainName);
  if (programURL instanceof URL === false) {
    throw new Error(`Invalid domain name`);
  }
  return HTTP.get("/api/v1/Subprograms/findByHost", {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      url: domainStringToFQDN(getData(DOMAIN_NAME)),
    },
    params: {
      host: programURL?.host,
    },
  }).then((response) => {
    response.data.url = programURL.origin;
    return response;
  });
};

/**
 * Attempts to log-in using Microsoft provided token
 * @param {Object} payload - ssoTokenAuth payload
 * @param {string} payload.domain - program domain name to validate
 * @param {string} payload.token - Microsoft provided authentication token
 */
export const ssoTokenLogin = async (
  { domain, token },
  { layout, redirect, hideHeaderOptions } = {}
) => {
  const programURL = domainStringToURL(domain);
  if (programURL instanceof URL === false) {
    throw new Error(`Invalid domain name`);
  }

  let ssoOptions = await _getSsoOptionsByTab();
  if (layout) {
    ssoOptions.layout = layout;
  }
  if (redirect) {
    ssoOptions.redirect = redirect;
  }
  if (hideHeaderOptions) {
    ssoOptions.hideHeaderOptions = hideHeaderOptions;
  }

  return HTTP.get("/azure-sso/validate", {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    params: {
      domain: `${programURL.protocol}//${programURL?.host}`,
      sso_options: ssoOptions,
    },
  });
};

/**
 * Retrive the subprogram configuration. Including logo, password reminders, etc.
 *
 * @returns Promise<Object>
 */
export const getSubprogramConfig = () => {
  return HTTP.get(`/api/v1/SubprogramConfigurations`, {
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      url: domainStringToFQDN(getData(DOMAIN_NAME)),
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data) {
          console.error(error.response.data.message);
        }
      }
    });
};

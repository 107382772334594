<template>
  <Layout v-if="initLoading">
    <div class="initial-loading">
      <Loading class="primary center" />
    </div>
  </Layout>
  <Layout v-else>
    <div class="program-name-label">Sign in with your program URL</div>
    <div id="formContent">
      <form @submit.prevent="submit">
        <Input
          label="Program URL"
          v-model="domainName"
          placeholder="Enter your program URL"
          required
        />

        <ErrorMessage :message="domainError" />

        <Loading v-if="domainLoading" class="primary center" />
        <button
          v-else
          type="submit"
          :disabled="emailLoading"
          class="block-button"
        >
          Next
        </button>
      </form>

      <div class="login-option-divider">
        <div class="divider"></div>
        <div class="text">OR Sign in with your email</div>
        <div class="divider"></div>
      </div>

      <form @submit.prevent="generateValidationCode">
        <Input
          type="email"
          label="Email Address"
          v-model="email"
          required
          placeholder="Enter your email address"
          :pattern="emailValidation"
          error-message="Sorry, but that email is invalid."
        />

        <ErrorMessage :message="emailServerError" />

        <Loading v-if="emailLoading" class="primary center" />
        <button
          v-else
          type="submit"
          :disabled="domainLoading"
          class="block-button"
        >
          Sign in with email
        </button>
      </form>

      <div class="login-message">
        We'll email you a code to display your available programs.
      </div>
    </div>
  </Layout>
</template>

<script>
import * as microsoftTeams from "@microsoft/teams-js";
import { emailLogin, initialize, verifyDomainName } from "../../api/coe";
import { domainStringToFQDN } from "./../../api/utils";
import { saveData, getData } from "./../../helpers/storage";

import {
  PROGRAM_ID,
  DOMAIN_NAME,
  VALIDATION_DOMAIN,
  VALIDATION_EMAIL,
  LOG_OUT_TAB_NAME
} from "../../constants";

export default {
  data() {
    return {
      initLoading: true,

      domainName: "",
      domainLoading: false,
      domainError: "",
      domainValidation: VALIDATION_DOMAIN,

      email: "",
      emailLoading: false,
      emailServerError: "",
      emailValidation: VALIDATION_EMAIL,
    };
  },
  methods: {
    async submit() {
      try {
        this.domainError = "";
        this.domainLoading = true;

        if (this.domainName) {
          this.domainName = this.domainName.replace(/\/$/, "")
        }
        saveData(DOMAIN_NAME, domainStringToFQDN(this.domainName));

        const { data } = await verifyDomainName({
          domainName: this.domainName,
        });

        if (data?.id) {
          saveData(PROGRAM_ID, data.id);
          this.$router.push({ path: "/login" });
        }
      } catch (error) {
        this.domainError = "Invalid Program URL";
      } finally {
        this.domainLoading = false;
      }
    },
    async generateValidationCode() {
      try {
        this.emailServerError = "";
        this.emailLoading = true;

        await emailLogin({ email: this.email });

        this.$router.push({
          path: "/verify-code",
          query: { email: this.email },
        });
      } catch (error) {
        this.emailLoading = false;
        this.emailServerError = error;
      }
    },
  },
  async created() {
    const programId = getData(PROGRAM_ID);
    let authenticated = false;

    if (programId) {
      const { access } = await initialize(programId);
      authenticated = access?.authenticated;
    }

    microsoftTeams.getContext((context) => {
      if (context.entityId == LOG_OUT_TAB_NAME) {
        this.$router.push({ path: "/log-out" });
      } else {

        if (programId) {
          try {
            if (authenticated) {
              this.$router.push({
                path: "/home",
              });
            } else {
              this.$router.push({
                path: "/login",
              });
            }
          } catch (error) {
            console.error("Unable to initialize extension : ", error);
          } finally {
            this.initLoading = false;
          }
        } else {
          this.initLoading = false;
        }
      }
    });
  }
};
</script>

<style lang="scss">
@import "./../../styles/index.scss";
</style>

<style scoped>
@import "./initial.scss";
</style>

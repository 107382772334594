import { STOREFRONT_TAB_NAME } from "../constants";

export const domainStringToFQDN = (domain) => {
  let fqdn = domain;
  if (!fqdn.toLowerCase().startsWith("http")) {
    fqdn = `https://${fqdn}`;
  }

  return fqdn;
};

export const domainStringToURL = (domain) => {
  const fqdn = domainStringToFQDN(domain);

  const url = new URL(fqdn);
  return url;
};

/**
 * @description
 * Identifies if user is currently on the storefront tab.
 * @param {string} activeTabName Identifier of the storefront tab name
 * @returns {boolean} True if user is on storefront tab, false otherwise
 */
export const isStorefront = (activeTabName) => {
  return activeTabName === STOREFRONT_TAB_NAME;
};

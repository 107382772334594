<template>
	<Layout class="layout-login" :programLogo="programLogo">
		<div class="login-card">
			<p class="card-title">{{ subprogramDetails?.name }}</p>
			<a
				id="email-id-link"
				class="card-title"
				>{{ subprogramDetails?.url }}</a
			>
			<form @submit.prevent="submit" class="form-login">
				<Loading v-if="loading" class="primary center" />

				<div v-if="showLogin && !loading">
					<Input
						v-model="memberId"
						required
						placeholder="EmployeeID / Username / Email"
            v-bind:themeColor="inputBorderColor"
						class="mb-10 form-input"
					/>

					<Input
						type="password"
						v-model="password"
						required
						placeholder="Password"
            v-bind:themeColor="inputBorderColor"
						class="form-input"
					/>
					<ErrorMessage :message="loginError" />

					<custom-button
						id="signin"
						:backgroundColor="buttonBgColor"
						:textColor="buttonTextColor"
						name="Sign In"
					></custom-button>
				</div>
			</form>

			<div class="forgot-card" v-if="showForgotPassword && !loading">
				Forgot <span class="primary-text" v-bind:style="linkTextColor" @click="forgotPassword">Password</span> ?
			</div>

			<div class="no-program">
				<div
					@click="privacyPolicyRedirect"
					class="private-policy-card primary-text"
					v-if="showPrivacyPassword"
					v-bind:style="linkTextColor"
				>
					Privacy Policy
				</div>
				<p>Not on your program?</p>
				<a
					@click.prevent="switchProgram"
					v-bind:style="linkTextColor"
					class="primary-text"
				>
					Switch Program
				</a>
			</div>
		</div>
	</Layout>
</template>

<script>
import {
  login,
  initialize,
  ssoTokenLogin,
  verifyDomainName,
  getSubprogramConfig,
  memberLogin,
  refreshOauthToken,
  LAYOUT,
  HIDE_HEADER_OPTIONS,
} from "../../api/coe";

import CustomButton from "./../../components/custom-button/CustomButton.vue";
import { getSSOToken } from "../home/helpers";
import {
  URL as PROGRAM_URL,
  PROGRAM_ID,
  LAST_VIEWED_PAGE,
  DOMAIN_NAME,
  ACTIVE_TAB,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CSRF,
  PROGRAM_DETAIL,
  PROGRAMS
} from "../../constants";
import { saveData, getData, removeData, setCookie, getCookie, deleteCookie } from "./../../helpers/storage";

export const NOMINATION_WIZARD_PATH =
  process.env.VUE_APP_NOMINATION_WIZARD_PATH;
const STOREFRONT_PATH = process.env.VUE_APP_STOREFRONT_PATH;
const STOREFRONT_TAB_NAME = process.env.VUE_APP_STOREFRONT_TAB_NAME;

let programId;

export default {
  data() {
    return {
      memberId: "",
      password: "",
      loading: false,
      loginError: "",
      subprogramConfig: null,
      subprogramDetails: null,
      showLogin: false
    };
  },
  components: { CustomButton },
  computed: {
    inputBorderColor() {
      return this.subprogramConfig
        ? this.subprogramConfig.theme.style_brand_primary
        : this.getThemeProperty('--primary');
    },
    buttonBgColor() {
      return this.subprogramConfig
        ? this.subprogramConfig.theme.style_brand_secondary
        : this.getThemeProperty('--primary');
    },
    buttonTextColor() {
      return this.subprogramConfig
        ? this.subprogramConfig.theme.style_brand_secondary_text
        : this.getThemeProperty('--button-text-color-default');
    },
    linkTextColor() {
      return this.subprogramConfig
        ? {
            color: this.subprogramConfig.theme.style_brand_primary,
          }
        : { color: this.getThemeProperty('--font-color-default') };
    },
    showForgotPassword() {
      if (!this.subprogramConfig) return false;
      return this.subprogramConfig.passwords.forgotpass_enabled > 0;
    },
    showPrivacyPassword() {
      if (!this.subprogramConfig) return false;
      return this.subprogramConfig.footer.privacy_link_visible > 0;
    },
    programLogo() {
      if (!this.subprogramDetails) return false;
      let programLogo = this.getProgramLogo(this.subprogramDetails);

      return programLogo;
    },
  },
  methods: {
    /**
     * @description
     * Check if program is configured with SSO access only
     * @param {boolean} isSsoOnly - True - Only login using SSO (no manual login available)
     *
     * @returns {void}
     */
    // eslint-disable-next-line no-unused-vars
    handleSsoOnlyLogin(isSsoOnly) {
      // //SSO login failed, check access
      // if (isSsoOnly === 1) {
      //   //show message and send user to program selection page
      //   this.switchProgram();
      // } else {
        //show login
        this.showLogin = true;
      // }
    },
    getThemeProperty(prop) {
      const r = document.querySelector(':root')
      return r.style.getPropertyValue(prop) || ''
    },
    async getProgramConfig() {

      const { data } = await getSubprogramConfig();

      this.subprogramConfig = data;

      // If SSO is enabled
      if (data.saml.SAML_SSO === 1) {
        //try to login using azure token

        try {
          let loggedIn = await this.ssoLogin();

          if (!loggedIn) {
            this.handleSsoOnlyLogin(data?.saml?.SAML_SSO_RESTRICT_ACCESS);
          }
        } catch {
          this.handleSsoOnlyLogin(data?.saml?.SAML_SSO_RESTRICT_ACCESS);
        }
      } else {
        let refresh_cookie = getCookie(REFRESH_TOKEN);
        if (refresh_cookie) {

          if (!getCookie(ACCESS_TOKEN)) {
            await this.refreshToken(refresh_cookie);
          }
          await this.handleLogin(getCookie(ACCESS_TOKEN));
        } else {
          //show login
          this.showLogin = true;
        }
      }
    },
    async ssoLogin() {
      this.loading = true;
      let loggedIn = false;
      try {
        // Get Microsoft Auth Token
        const token = await getSSOToken();
        // Attempt SSO Token based login
        // If we have token from Microsoft Teams
        // We can attempt SSO login to the program
        const tabUrl =
          getData(ACTIVE_TAB) == STOREFRONT_TAB_NAME
            ? STOREFRONT_PATH
            : NOMINATION_WIZARD_PATH;

        const tokenLogin = await ssoTokenLogin(
          {
            domain: getData(DOMAIN_NAME),
            token,
          },
          {
            layout: LAYOUT,
            hideHeaderOptions: HIDE_HEADER_OPTIONS,
            redirect: tabUrl,
          }
        );

        let url = new URL(tokenLogin?.data?.sso_url);
        saveData(PROGRAM_URL, url.pathname);
        this.loggedIn = true;
        this.gotoHome();
      } catch (error) {
        console.error("invalid url", error);
      } finally {
        this.loading = false;
      }
      return loggedIn;
    },
    async refreshToken(refresh_cookie) {
      const { data } = await refreshOauthToken({
        domain: getData(DOMAIN_NAME),
        refresh_token: refresh_cookie
      });

      setCookie(ACCESS_TOKEN, data.token, 3600 * 1000);
      setCookie(REFRESH_TOKEN, data.refresh_token, 3600 * 1000 * 24 * 7);
    },
    async submit() {
      this.loginError = "";

      try {
        this.loading = true;

        const {
          data: { token, refresh_token, _csrf },
        } = await memberLogin(
          this.memberId,
          this.password,
          programId,
          getData(DOMAIN_NAME)
        );

        // set token cookie for 1 hour
        setCookie(ACCESS_TOKEN, token, 3600 * 1000);
        // set refresh token cookie for 7 days
        setCookie(REFRESH_TOKEN, refresh_token, 3600 * 1000 * 24 * 7);
        setCookie(CSRF, _csrf);

        await this.handleLogin(token);
      } catch (error) {
        console.error({ error });
        if (error?.response?.data?.error?.statusCode === 403) {
          this.loginError = "Invalid credentials";
        } else {
          this.loginError = "Internal Server Error";
        }
      } finally {
        this.loading = false;
      }
    },
    async handleLogin(token) {
        const {
          data: { url },
        } = await login({
          token,
          domain: getData(DOMAIN_NAME)
        });

        saveData(PROGRAM_URL, url);

        this.gotoHome();
    },
    getProgramLogo(subprogramDetails) {
      return `${subprogramDetails?.url}/asset/${subprogramDetails?.programLogo}`;
    },
    gotoHome() {
      this.$router.push({ path: "/home" });
    },
    forgotUsername() {
      window.open(this.subprogramDetails.url + "/auth/forgot");
    },
    forgotPassword() {
      window.open(this.subprogramDetails.url + "/auth/forgot");
    },
    privacyPolicyRedirect() {
      window.open(this.subprogramDetails.url + "/privacy");
    },
    switchProgram() {
      removeData(DOMAIN_NAME);
      removeData(PROGRAM_ID);
      removeData(PROGRAM_DETAIL);
      removeData(PROGRAMS),
      removeData(LAST_VIEWED_PAGE);
      deleteCookie(ACCESS_TOKEN);
      deleteCookie(REFRESH_TOKEN);
      this.$router.push({ path: "/" });
    },
  },
  created() {
    programId = getData(PROGRAM_ID);
    if (!programId) {
      this.switchProgram();
    }

    (async () => {
      try {
        // We need to initialize the program first to setup the session cookie
        // which is needed for the other calls like `getSubprogramConfig`.
        const { access } = await initialize(programId);
        if (access?.authenticated) {
          this.switchProgram();
        }
      } catch (error) {
        console.error("Unable to initialize extension : ", error);
      }
    })();
  },
  mounted() {
    (async () => {
        await initialize(programId);
        await this.getProgramConfig();
    })();
    verifyDomainName({
      domainName: getData(DOMAIN_NAME),
    }).then((response) => {
      this.subprogramDetails = response.data;
      saveData(PROGRAM_DETAIL, JSON.stringify(response.data));
    });
  }
};
</script>

<style scoped>
@import "./login.scss";
</style>

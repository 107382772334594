import {
  HIDE_HEADER_OPTIONS,
  HIDE_HEADER_OPTIONS_GET_TAB,
  NOMINATION_WIZARD_PATH,
  STOREFRONT_PATH,
  STOREFRONT_TAB_NAME,
  LAYOUT,
  LAYOUT_GET_TAB,
} from "../constants";

export class LayoutManager {
  constructor(tabName) {
    this.tabName = tabName;
    this.isStorefront = this.tabName === STOREFRONT_TAB_NAME;
    this.layouts = [new GetTabLayout(), new GiveTabLayout()];
  }

  get layout() {
    const layoutConfig = this.isStorefront ? this.layouts[0] : this.layouts[1];
    return layoutConfig;
  }
}
class TabLayout {
  constructor() {
    this.layout = "";
    this.redirect = "";
    this.hideHeaderOptions = "";
  }
}

class GiveTabLayout extends TabLayout {
  constructor() {
    super();
    this.layout = LAYOUT;
    this.redirect = NOMINATION_WIZARD_PATH;
    this.hideHeaderOptions = HIDE_HEADER_OPTIONS;
  }
}

class GetTabLayout extends TabLayout {
  constructor() {
    super();
    this.layout = LAYOUT_GET_TAB;
    this.redirect = STOREFRONT_PATH;
    this.hideHeaderOptions = HIDE_HEADER_OPTIONS_GET_TAB;
  }
}

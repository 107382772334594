import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Layout from "./components/layout/layout.vue";
import Loading from "./components/loading/loading.vue";
import Input from "./components/input/Input.vue";
import ErrorMessage from "./components/error-message/ErrorMessage.vue";
import * as microsoftTeams from "@microsoft/teams-js";

microsoftTeams.initialize(() => {
  createApp(App)
    .use(router)
    .component("Layout", Layout)
    .component("Loading", Loading)
    .component("Input", Input)
    .component("ErrorMessage", ErrorMessage)
    .mount("#app");
});


/****  Use the code below instead of initializing microsoft for local dev  ****/

// const app = createApp(App)
//   .use(router)
//   .component("Layout", Layout)
//   .component("Loading", Loading)
//   .component("Input", Input)
//   .component("ErrorMessage", ErrorMessage)
// app.mount('#app')

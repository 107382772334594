/**
 * Any API related to the mobile proxy is located in this file.
 * Functionality for validation code generation and program list
 * based on provided email.
 */

import { HTTP } from "../base";

/**
 * @typedef {object} Program
 * @property {string} name
 * @property {string} url
 */

/**
 * @description
 * Generate verification code and sends it to the provided email address.
 * Verification code is used to make sure user has access to provided e-mail address.
 * @param {Object} emailLoginPayload
 * @param {string} emailLoginPayload.email - User email address used to find the associated P2M programs
 * @returns Promise<>
 */
export const emailLogin = ({ email }) => {
  let url = `/verification_code`;
  return HTTP.get(url, { params: { email } });
};

/**
 * @description
 * Validates if email / code combination is correct.
 * @param {Object} verifyCodePayload
 * @param {string} verifyCodePayload.code - Unique token generated for the user email to ensure user has access to provided e-mail address.
 * @param {string} verifyCodePayload.email - E-Mail address provided during verification code request.
 * @returns Promise<boolean> If code is valid returns true, false otherwise
 */
export const verifyCode = async ({ code, email }) => {
  if (!code) {
    return false;
  }
  const { data, status } = await getProgramList({
    email,
    verificationCode: code,
  });
  if (status !== 400 && Array.isArray(data.programs)) {
    return data.programs;
  } else {
    return null;
  }
};

/**
 * @description
 * Retrieves list of programs associated with provided email address
 * @param {object} payload
 * @param {string} payload.email - Email address to use for the programs lookup
 * @param {string} payload.verificationCode - The code that user received on the provided email.
 * @returns Promise<Program|[]>
 */
export const getProgramList = ({ email, verificationCode }) => {
  let url = "/programs";

  return HTTP.get(url, { params: { email, verificationCode } });
};

/**
 * Attempts to get authorization cookie for refresh token
 * @param {Object} loginPayload
 * @param {string} loginPayload.username - Unique identifier of the memeber
 * @param {string} loginPayload.password - User password
 *
 * @returns Promise<>
 */
 export const memberLogin = (username, password, subprogramId, domain) => {
  return HTTP.get("/member/login", {
    withCredentials: true, params: { username, password, subprogramId, domain }}); 
};

/**
 * @description
 * Get a refresh token to generate now JWT token for SSO session. This is valid for 7 days 
 * @param {object} payload
 * @param {string} payload.domain - Program URL
 * @param {string} payload.refresh_token - Value of refresh token recieved from token api resonse
 * @returns Promise<>
 */
 export const refreshOauthToken = ({ domain, refresh_token }) => {
  let url = "/oauth/refresh_token";

  return HTTP.get(url, { params: { domain, refresh_token } });
};
